import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { navigate } from 'gatsby';
import Noppon from '../../../assets/pages/ourteam/Noppon.jpg';
import Amornched from '../../../assets/pages/ourteam/Amornched.jpg';
import Tananan from '../../../assets/pages/ourteam/Tananan.jpg';
import { Button, ButtonType } from '../../Common/Button';
import { PageHeaderText } from '../../../utils/TextLabel';

const StyledContainer = styled.section(() => [
  tw`relative z-10 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto mb-10 md:mb-16 xl:mb-24`
]);

const OurValue = styled.div(() => [tw``]);

const Title = styled.h2(() => [
  tw`flex w-full text-2xl lg:text-[32px] text-left font-bold text-spaceGray xl:mb-14 sm:mb-6`
]);

const ValueContainer = styled.div(() => [
  tw`grid grid-cols-3 overflow-x-auto max-w-full gap-y-4 md:gap-20 lg:gap-32 xl:gap-40 bg-transparent my-8`
]);

const ValueBox = styled.div(() => [
  tw`col-span-3 sm:col-span-1 flex justify-start items-start sm:flex-col flex-row sm:items-center`
]);

const ImageContainer = styled.div(() => [
  tw`relative flex justify-center items-end h-[170px] w-[121px] md:w-[200px] md:h-[280px] lg:w-[240px] lg:h-[336px] rounded-[5px] overflow-hidden`
]);

const Image = styled.img(() => [tw`w-full`]);

const ValueTitle = styled.h4(() => [tw`font-semibold text-base md:text-[20px] text-orange sm:text-center mt-6 leading-7`]);

const LowerValue = styled.span(() => [
  tw`font-normal text-spaceGray text-sm sm:text-center leading-5 md:leading-7 md:text-base`
]);

export const Background = styled.div(() => [tw`absolute top-0 h-[785px] w-screen background-color[#E46B25] `]);

const DescBox = styled.div(() => [tw`flex flex-col justify-center sm:items-center ml-4 sm:ml-0 mt-0 sm:mt-4 md:mt-0`]);

interface ManagementTeamSectionProps {
  className?: string;
}

interface ValueProps {
  icon: any;
  firstname: string;
  lastname: string;
  desc1: string;
  desc2?: string;
}

const valueList: ValueProps[] = [
  {
    icon: Noppon,
    firstname: 'Noppon',
    lastname: 'Anukunwithaya',
    desc1: 'Chief Executive Officer',
    desc2: '& Co-Founder'
  },
  {
    icon: Amornched,
    firstname: 'Amornched',
    lastname: 'Jinda-apiraksa',
    desc1: 'Chief Operating Officer',
    desc2: '& Co-Founder'
  },
  {
    icon: Tananan,
    firstname: 'Tananan',
    lastname: 'Tangthanachaikul',
    desc1: 'Chief Technology Officer',
    desc2: '& Co-Founder'
  }
];

const ManagementTeamSection: React.FC<ManagementTeamSectionProps> = ({ className }) => (
  <>
    <StyledContainer className={className}>
      <OurValue>
        <PageHeaderText>Management Team</PageHeaderText>
        <ValueContainer>
          {valueList.map((list: ValueProps) => (
            <ValueBox key={`${list.firstname}_${list.lastname}`}>
              <ImageContainer>
                <Image src={list.icon} />
              </ImageContainer>
              <DescBox>
                <ValueTitle>
                  {list.firstname}
                  <br />
                  {list.lastname}
                </ValueTitle>
                <LowerValue>
                  {list.desc1}
                  <br />
                  {list.desc2}
                </LowerValue>
              </DescBox>
            </ValueBox>
          ))}
        </ValueContainer>
        <div className="w-full flex justify-center">
          <Button
            type={ButtonType.SECONDARY}
            onClick={() => navigate('/career')}
            className="w-56"
          >
            Join our team
          </Button>
        </div>
      </OurValue>
    </StyledContainer>
  </>
);

export default ManagementTeamSection;

import * as React from 'react';

import Page from '../components/Common/Page';
// import OurValueSection from '../components/pages/OurTeamPage/OurValueSection';
import IndexLayout from '../layouts';
import OurSquadSection from '../components/pages/OurTeamPage/OurSquadSection';
import ManagementTeamSection from '../components/pages/OurTeamPage/ManagementTeamSection';
import WorkEnvironmentSection from '../components/pages/WorkPage/WorkEnvironmentSection';
// import TeamMemberSection from '../components/pages/OurTeamPage/TeamMemberSection';

const OurTeamPage = () => (
  <IndexLayout transparent>
    <Page>
      <OurSquadSection />
      <ManagementTeamSection />
      {/* <TeamMemberSection /> */}
      {/* <OurValueSection /> */}
      {/* use one picture like home page */}
      <WorkEnvironmentSection />
    </Page>
  </IndexLayout>
);

export default OurTeamPage;

import * as React from 'react';
// import tw, { styled } from 'twin.macro';
// import { Pagination, Navigation } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import WorkEnv1 from '../../../assets/pages/index/WorkEnv1.jpg';
// import WorkEnv2 from '../../../assets/pages/index/WorkEnv2.jpg';
// import WorkEnv3 from '../../../assets/pages/index/WorkEnv3.jpg';
// import WorkEnv4 from '../../../assets/pages/index/WorkEnv4.jpg';

// const WorkEnvironmentPictures = [
//   { src: WorkEnv1, alt: 'Work Environment 1' },
//   { src: WorkEnv2, alt: 'Work Environment 2' },
//   { src: WorkEnv3, alt: 'Work Environment 3' },
//   { src: WorkEnv4, alt: 'Work Environment 4' },
// ];


// const StyledContainer = styled.section(() => [
//   tw`relative mb-10 md:mb-16 xl:mb-24 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto`
// ]);

const WorkEnvironmentSection: React.FC = () => {
  return (
    <div className="w-full h-auto relative">
      <div className="w-full h-full absolute z-20 bg-black bg-opacity-30" />
      <img
        alt="Team"
        src={WorkEnv1}
        className="w-full object-cover"
        style={{ transform: 'scaleX(-1)' }}
      />
    </div>
    //   <StyledContainer>
    //   <Swiper
    //     modules={[Navigation, Pagination]}
    //     navigation
    //     pagination={{
    //       clickable: true
    //     }}
    //     slidesPerView={1}
    //     autoplay={{
    //       delay: 2000
    //     }}
    //   >
    //     {WorkEnvironmentPictures.map(picture => (
    //       <SwiperSlide key={`${picture.src}_${picture.alt}`}>
    //         <div className="w-full aspect-w-4 aspect-h-3 md:aspect-w-16 md:aspect-h-9">
    //           <img className="w-full object-cover" src={picture.src} alt={picture.alt} />
    //         </div>
    //       </SwiperSlide>
    //     ))}
    //   </Swiper>
    // </StyledContainer>
  );
};

export default WorkEnvironmentSection;

import * as React from 'react';
import tw, { styled } from 'twin.macro';
import Squad from '../../../assets/pages/ourteam/Squad.jpg';
import { PageHeaderText } from '../../../utils/TextLabel';

const StyledContainer = styled.section(() => [
  tw`relative md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto mb-10 md:mb-16 mt-4 md:mt-6 xl:mt-12`
]);

const ValueContainer = styled.div(() => [
  tw`grid grid-cols-3 overflow-x-auto max-w-full md:gap-x-12 xl:gap-x-28 gap-y-4 bg-transparent`
]);

const ValueBox = styled.div(() => [
  tw`col-span-3 flex justify-center md:col-span-1 xl:items-start flex-col sm:justify-start sm:items-center`
]);

const SquadImage = styled.img(() => [tw`w-full max-h-[544px] object-cover`]);

const Title = styled.h2(() => [
  tw`flex w-full text-2xl lg:text-[28px] mt-0 lg:justify-start font-bold text-spaceGray xl:mb-14 mb-6`
]);

const ValueTitle = styled.h4(() => [
  tw`font-semibold w-full text-xl md:text-2xl text-orange text-left mt-0 mb-1 md:mb-2.5`
]);

const LowerValue = styled.span(() => [tw`font-light mt-0 text-sm md:text-base lg:text-lg`]);

interface OurSquadSectionProps {
  className?: string;
}

interface SquadProps {
  title: string;
  desc: string;
}

const squadList: SquadProps[] = [
  {
    title: 'Developer',
    desc:
      'With work experience in a wide range of industries. In addition to a strong team, the company has a comprehensive tech stack for different operations. To deliver excellent quality work to our customers.'
  },
  {
    title: 'Business',
    desc:
      'Business team to help you analyze weaknesses & strengths in your business and help in laying out business development guidelines to become the No. 1 in your industry.'
  },
  {
    title: 'Marketing',
    desc:
      'Marketing team that will help your product become more known in the market. Help drive your sales or usage through online and offline channels.'
  }
];

const OurSquadSection: React.FC<OurSquadSectionProps> = ({ className }) => (
  <>
    <SquadImage src={Squad} />
    <StyledContainer className={className}>
      <PageHeaderText>Our Squad</PageHeaderText>
      <ValueContainer className="mt-6">
        {squadList.map((list: SquadProps) => (
          <ValueBox key={list.title}>
            <h2 className="font-semibold w-full text-xl md:text-[22px] text-orange text-left mt-0 mb-1 md:mb-2.5">{list.title}</h2>
            <span className="font-light mt-0 text-sm md:text-base lg:text-[18px] leading-8">{list.desc}</span>
          </ValueBox>
        ))}
      </ValueContainer>
    </StyledContainer>
  </>
);

export default OurSquadSection;
